<template>
  <div class="znzz">
    <div class="header">
      <div class="zhuti">
        <div class="h1">数字孪生</div>
        <div class="h2">
          <p>以大数据、工业建模为基础，生产制造模型仿真、工业可</p>
          <p> 视化、厂房实时监控、设备安全监管、厂房环境安全等。</p>
        </div>
      </div>
    </div>
    <div class="bg cj">
      <div class="zhuti">
        <div class="titles pad56" id="m1">应用场景解决方案</div>
        <div class="cjlist flex"  v-for="(li,index) in jjfaList" :key="index">
          <div class="cimages"><el-image :src="li.image"></el-image></div>
          <div class="mag32">
            <div class="name">{{li.title}}</div>
            <div class="desc ellipsis3 ">{{li.titleSub}}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="bg3">
        <div class="zhuti">
          <div class="titles" id="m2">数字孪生平台技术领域</div>
          <div :class="`jicheng jicheng${index}`" v-for="(li,index) in jslyList" :key="index">
            <el-popover
                placement="top"
                width="302"
                trigger="hover"
                :content="li.titleSub">
              <el-image slot="reference" :src="li.image"></el-image>
            </el-popover>
          </div>
        </div>
    </div>
    <div class="bg2">
      <div class="zhuti szls">
        <div class="titles" style="color: #fff" id="m2">数字孪生案例</div>
        <el-row class="tabs">
            <el-col span="4"  :offset="index!=0?1:0" v-for="(li,index) in anliList" :key="index">
              <div class="tablist" :class="{'isactive':number==index}" @click="gotabs(index)">
                <div class="tabimage"><el-image :src="li.icon"></el-image></div>
                <div>{{li.title}}</div>
                <div>{{li.titleSub}}</div>
              </div>
            </el-col>
        </el-row>
        <div class="anli">
          <video ref="videoPlay" v-if="anliList[number].image.match('mp4')" autoplay muted loop class="video" controls="controls">
            <source  src="" type="video/mp4">
          </video>
          <el-image v-else  :src="anliList[number].image"></el-image>
        </div>
      </div>
    </div>
    <div class="bg4">
      <div class="titles">需求咨询留言</div>
      <div class="advice">
          <Advice></Advice>
      </div>
    </div>
    <div class="zhuti">
      <div class="more">
        <div class="titles" id="m5">第三方推荐</div>
        <MoreList :moreList="moreList"></MoreList>
      </div>
    </div>
  </div>
</template>

<script>


import {digitaltwinindex, productionmanagementindex} from "../../request/moudle/znzz";
import Advice from "../common/advice.vue";
import MoreList from "../common/moreList.vue";
export default {
  name: "",
  components:{
    Advice,
    MoreList
  },
  data(){
    return  {
      datas:'',
      number:0,
      moreList:[],
      jjfaList:[],
      anliList:[],
      jslyList:[]
    }
  },

  mounted(){
    this.loadconfig()
  },
  methods:{
    gotabs(index){
      this.number=index
      setTimeout(()=>{
        this.$refs.videoPlay.src = this.anliList[this.number].image;
      },500)
    },
    loadconfig(){
      this.$api.znzz.digitaltwinindex().then((res)=>{
        this.datas=res.data
        this.jjfaList=res.data.jjfaList
        this.anliList=res.data.anliList
        this.moreList=res.data.moreList
        this.jslyList=res.data.jslyList
        this.$nextTick(() => {
          this.$refs.videoPlay.src = this.anliList[0].image;
        });

      })
    },
    remove(e){
      let hdimage=this.$refs.hdimage
      for (const val of hdimage){
        val.className='hdimage display'
      }
      e.target.className='hdimage remove-class show'
    },
  }
}
</script>

<style lang="scss" scoped>
.znzz{
  background: #fff;
}
.mag32{
  margin: 24px;
  width: 410px;
}
.szls{
  .anli{
    width: 1200px;
    height: 500px;
  }
}
.header{
  height: 230px;
  background: url("../../assets/banner/shuzi.png") no-repeat;
  background-size: 100% 100%;
  padding-top: 90px;
  color: #fff;
  .h1{
    font-size: 42px;
    margin-top: 25px;
  }
  .h2{
    font-size: 20px;
    line-height: 30px;
    margin-top: 15px;
  }
}
.tabs{
  overflow: hidden;
  margin-bottom: 35px;
  .tablist{
    width: 160px;
    height: 160px;
    text-align: center;
    color: #fff;
    cursor: pointer;
    border-radius: 6px;
  }
  .tabimage{
    display: inline-block;
    width: 80px;
    height: 80px;
    margin: 12px  auto;
  }
  .isactive{
    background: rgba(255,255,255,0.2);
  }
}
.more{
  overflow: hidden;
  padding: 56px;
  .mlist{
    text-align: center;
    float: left;
    width: 25%;
  }
  .minage{
    width: 120px;
    height: 120px;
    margin: 0 auto;
    margin-bottom: 15px;
  }
}
.bg{
  background: #F4F9FE;
  padding:56px;
}
.bg2{
  background: url("../../assets/znzz/bg2.png") no-repeat;
  background-size: 100% 100%;
  padding: 56px 0;
}
.bg3{
  background: url("../../assets/znzz/bg5.png") no-repeat;
  background-size: 100% 100%;
  height: 850px;
  position: relative;
  .jicheng{
    position: absolute;
    cursor: pointer;
  }
  .jicheng0{
    width: 277px ;
    height: 213px;
    top: 490px;
  }
  .jicheng1{
    width: 272px ;
    height: 210px;
    top: 210px;
    left: 185px;
  }
  .jicheng2{
    width: 343px ;
    height: 264px;
    top: 295px;
    left: 434px;
  }
  .jicheng3{
    width: 272px ;
    height: 210px;
    top: 210px;
    right: 185px;
  }
  .jicheng4{
    width: 272px ;
    height: 210px;
    top: 490px;
    right: 0;
  }
  .titles{
    position: absolute;
    left: 50%;
    top: 56px;
    margin-left: -100px;
  }
}
.bg4{
  background: #FFF;
  padding: 56px 0 0 0;
  .advice {
   width: 1120px;
   height: 80px;
   padding: 50px 40px;
   margin: 0 auto 30px auto;
   border-radius: 6;
   background: #fff;
   box-shadow: 1px 1px 5px rgba(0,0,0,.1);
  }
}
.allfangan{
  overflow: hidden;
  .fangan{
    float: left;
    width: 384px;
    margin: 8px;
    .image{
      width: 100%;
      height: 220px;
      margin-right: 32px;
    }
    .title{
      color: #1D1D1D;
      font-weight: bolder;
      font-size: 16px;
      margin: 15px 0;
    }
    .h2{
      height: 37px;
      color: #999;
    }
  }
}

.bg1{
  background: url("../../assets/znzz/bg1.png") no-repeat;
  background-size: 100% 100%;
  height: 500px;
}

.cj{
  overflow: hidden;
  .cjlist{
    width: 560px;
    height: 160px;
    float: left;
    position: relative;
    margin: 16px;
    background: #fff;
    border-radius: 8px;
  }
  .cimages{
    width: 100px;
    height: 100px;
    margin: 29px 10px;
  }
  .name{
    font-size: 18px;
    font-weight: bolder;
    color: #1D1D1D;
    margin: 12px 0;
  }
  .desc{
    color: #999;
  }
}
.zhuti{
  width: 1200px;
  margin: 0px auto;
  position: relative;
}
.titles{
  text-align: center;
  font-weight: bolder;
  font-size: 20px;
  padding-bottom: 56px;
  color: #1D1D1D;
}
.pad56{
  padding-top: 56px;
}
</style>